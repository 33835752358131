<template>
  <v-row no-gutters align="center">
    <v-col cols="12" sm="6" class="d-flex align-center mb-3 mb-md-0">
      <img
        :src="item.product.mediaURL"
        class="mr-1"
        height="65px"
        width="65px"
        :alt="item.product.name"
        onerror="this.onerror=null;this.src='/no-icon.png'"
      />
      <div class="d-flex flex-column default--text">
        <span class="text-body-2 font-weight-bold">
          {{ item.product.name }}</span
        >
        <!-- <span class="text-body-2">{{ item.product.shortDescr }}</span> -->
        <span
          class="weight-unit font-weight-bold"
          v-if="
            item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
              item.product.productInfos.PACKAGE_DESCR
          "
        >
          {{ item.product.productInfos.PACKAGE_DESCR }}
        </span>
        <div class="product-item-info text-body-2" v-if="itemOption">
          <i>{{ itemOption }}</i>
        </div>
        <div
          v-if="
            itemAdjustment &&
              itemAdjustment.userGiftCertificateId &&
              itemAdjustment.giftCertificate
          "
          class="cart-item-info promo--text"
        >
          <em>{{ itemAdjustment.giftCertificate.name }}</em>
          <!-- -{{ $n(itemAdjustment.valueVariation, "currency") }} -->
        </div>
      </div>
    </v-col>
    <v-col cols="4" sm="2" class="d-flex justify-center">
      <OrderDetailQuantity :item="item" />
    </v-col>
    <v-col cols="4" sm="2" class="d-flex justify-center">
      <OrderItemPrice :item="item" :showWeightUnitBase="true" />
    </v-col>
    <v-col cols="4" sm="2" offset-sm="0" class="d-flex justify-center">
      <OrderGrossTotal :item="item"></OrderGrossTotal>
    </v-col>
  </v-row>
</template>
<script>
import OrderDetailQuantity from "@/components/orders/OrderDetailQuantity.vue";
import OrderItemPrice from "@/components/orders/OrderItemPrice";
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";

import find from "lodash/find";

export default {
  name: "OrderDetailRow",
  props: {
    item: { type: Object },
    order: { type: Object }
  },
  components: { OrderItemPrice, OrderGrossTotal, OrderDetailQuantity },
  computed: {
    hasPromo() {
      return (
        this.item.product.priceStandardDisplay &&
        this.item.unitPrice !== this.item.product.priceStandardDisplay
      );
    },
    itemAdjustment() {
      if (
        this.order.orderAdjustmentSummary &&
        this.order.orderAdjustmentSummary.orderAdjustments
      ) {
        let adj = find(
          this.order.orderAdjustmentSummary.orderAdjustments,
          orderAdjustment =>
            orderAdjustment.orderItemId == parseInt(this.item.orderItemId)
        );
        return adj;
      }
      return null;
    },
    itemOption() {
      return this.item.orderItemInfos?.size;
    }
  }
};
</script>
