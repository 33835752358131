<template>
  <v-card elevation="0" class="order-detail-info">
    <v-row no-gutters align="center">
      <v-col cols="12" sm="7">
        <v-row
          no-gutters
          class="justify-space-between text-body-2 default--text font-weight-bold"
        >
          <v-col cols="5" md="4">
            <span>{{ $t("orders.header.orderNumber") }}</span>
          </v-col>
          <v-col cols="5" md="3">
            <span>{{ $t("orders.header.status") }}</span>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="justify-space-between text-body-1 font-weight-bold"
        >
          <v-col cols="5" md="4">
            <span class="primary--text">#{{ order.orderId }}</span>
            <div
              class="eugenio-img accent pa-1 mt-1"
              v-if="order.orderTypeId == 4"
            >
              <v-img contain src="/img_layout/services_icons/eugenio.svg" />
            </div>
          </v-col>
          <v-col cols="5" md="3">
            <OrderStatusBadge v-bind:order="order" class="default--text" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="5"
        class="actions d-flex justify-center justify-sm-end mt-10 mt-sm-0"
      >
        <v-col
          cols="12"
          md="6"
          class="actions-cta d-flex justify-space-between justify-sm-end pa-0 align-center"
        >
          <OrderQrCode
            v-if="order.orderInfos.PIN_CODE"
            :codeValue="order.orderInfos.PIN_CODE"
            :width="40"
            :height="40"
          />

          <div
            class="d-flex flex-column justify-center align-center"
            v-if="order.isEditable"
          >
            <v-btn
              @click.stop.prevent="editOrder(order.orderId)"
              class="primary white--text"
              depressed
            >
              <v-icon>$edit</v-icon>
            </v-btn>
            <span
              class="text-body-2 primary--text text-center text-nowrap small mt-2"
              >{{ $t("orders.button.edit") }}</span
            >
          </div>

          <div
            class="d-flex flex-column justify-center align-center"
            v-if="order.isPayable"
          >
            <v-btn
              @click.stop.prevent="showPayment"
              class="primary white--text"
              depressed
            >
              <v-icon>$creditcard</v-icon>
            </v-btn>
            <span
              class="text-body-2 primary--text text-center text-nowrap small mt-2"
              >{{ $t("orders.button.retryPayment") }}</span
            >
          </div>

          <div
            class="d-flex flex-column justify-center align-center"
            v-if="!isRider"
          >
            <v-btn
              @click.stop.prevent="addAllToCart(order.orderId)"
              class="primary white--text w-100"
              depressed
            >
              <v-icon>$cart</v-icon>
            </v-btn>
            <span
              class="text-body-2 default--text text-center text-nowrap small mt-2"
              >{{ $t("orders.buttons.reorderOrder") }}</span
            >
          </div>
          <!--
          <div
            class="d-flex flex-column justify-center align-center"
            v-if="!isRider && order.orderStatusId == 8"
          >
            <v-btn
              @click.stop.prevent="addAllToCart(order.orderId)"
              class="primary white--text w-100"
              target="_blank"
              :href="receiptUrl"
              @click.prevent="handleReceiptDownload(receiptUrl, order.orderId)"
              depressed
            >
              <v-icon>$receipt</v-icon>
            </v-btn>
            <span
              class="text-body-2 default--text text-center text-nowrap small mt-2"
              >Scarica fattura o scontrino</span
            >
          </div>
        -->

          <div class="d-flex flex-column justify-center align-center">
            <v-btn
              @click="printOrder"
              class="primary white--text w-100"
              depressed
            >
              <v-icon>$print</v-icon>
            </v-btn>
            <span class="text-body-2 default--text text-center small mt-2">{{
              $t("orders.button.print")
            }}</span>
          </div>
          <div
            class="d-flex flex-column justify-center align-center  ml-4"
            v-if="
              isCordova &&
                (order.orderStatusId == 8 || order.orderStatusId == 10)
            "
          >
            <v-btn
              @click.stop.prevent="addAllToCart(order.orderId)"
              class="primary white--text"
              v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
            >
              <v-icon>$cart</v-icon>
            </v-btn>
            <span class="text-body-2 default--text text-center small mt-2">{{
              $t("orders.button.reorder")
            }}</span>
          </div>

          <div
            class="d-flex flex-column justify-center align-center"
            v-if="order.isDeletable"
          >
            <v-btn
              color="error"
              @click.stop.prevent="deleteOrder(order.orderId)"
              depressed
            >
              <v-icon>$delete</v-icon>
            </v-btn>
            <span
              class="text-body-2 error--text text-center text-nowrap small mt-2"
            >
              {{ $t("orders.button.delete") }}
            </span>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.order-detail-info {
  .actions {
    &-cta {
      justify-content: space-evenly;
      @media (min-width: 600px) {
        gap: 15px;
      }
    }
  }
  .small {
    font-size: 12px;
    min-height: 16px;
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";
import OrderQrCode from "./OrderQrCode.vue";
import downloadDocumentMixin from "~/mixins/downloadDocument";
export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge, OrderQrCode },
  props: {
    order: { type: Object, required: true },
    isRider: { type: Boolean, default: false }
  },
  computed: {
    receiptUrl() {
      let documentTypeId = 1;
      let orderId = this.order.orderId;
      return `/ebsn/api/order/document?document_type_id=${documentTypeId}&type=pdf&order_id=${orderId}`;
    }
  },
  methods: {
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
